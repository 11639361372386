import React, { useEffect, useState } from 'react';
import styles from './alert.module.scss'; // Adjust the path as necessary
import { FaCheckCircle } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { RiErrorWarningFill } from 'react-icons/ri';
import { BsInfoCircle } from 'react-icons/bs';
import { useHistory } from 'react-router-dom'; // For navigation

const Alert = ({
  message,
  type = 'info', // 'info', 'warning', 'success', 'error'
  duration = 5000, // Duration to auto-hide the alert
  onClose = () => {}, // Callback function when the alert is closed
  goBackText, // Text for the "Go Back" button
  goBackLink, // Link for the "Go Back" button
  button1Text, // Text for the first button
  button1Link, // Link for the first button
  button2Text, // Text for the second button
  button2Link, // Link for the second button
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isAlertVisible, setAlertVisible] = useState(true); // Manage alert visibility
  const history = useHistory(); // For navigation

  useEffect(() => {
    if (isVisible) {
      // Show alert and start timer
      setAlertVisible(true);

      const timer = setTimeout(() => {
        setIsVisible(false);
        setAlertVisible(false);
        onClose();
      }, duration);

      // Cleanup timer if the component unmounts or if duration changes
      return () => clearTimeout(timer);
    }
  }, [isVisible, duration, onClose]);

  const handleClose = () => {
    setIsVisible(false);
    setAlertVisible(false);
    onClose();
  };

  const handleGoBack = () => {
    if (goBackLink) {
      history.push(goBackLink);
    }
  };

  if (!isAlertVisible) return null;

  // Determine icon based on type
  const renderIcon = () => {
    switch (type) {
      case 'success':
        return <FaCheckCircle size={25} />;
      case 'error':
        return <RiErrorWarningFill size={30} />;
      case 'info':
        return <BsInfoCircle size={25} />;
      case 'warning':
        return <RiErrorWarningFill size={25} />;
      default:
        return <BsInfoCircle size={25} />;
    }
  };

  return (
    <div className={`${styles.alert} ${styles[type]}`}>
      <div className={styles.icon}>
        {renderIcon()}
      </div>
      <div className={styles.message}>{message}</div>
      <div className={styles.buttonsContainer}>
        {button1Text && button1Link && (
          <a className={styles.button} href={button1Link}>
            {button1Text}
          </a>
        )}
        {button2Text && button2Link && (
          <a className={styles.button} href={button2Link}>
            {button2Text}
          </a>
        )}
      </div>
      {goBackText && goBackLink && (
        <button className={styles.goBackButton} onClick={handleGoBack}>
          {goBackText}
        </button>
      )}
      {!goBackText && (
        <button className={styles.closeButton} onClick={handleClose}>
          <AiOutlineClose size={25} />
        </button>
      )}
    </div>
  );
};

export default Alert;
