import FeatureBox from "./FeatureBox";
import styles from "./feature-section.module.scss";

const FeatureScreen = () => {
  return (
    <section className={styles.sectionFeatures}>
      <div className={styles.row}>
        <FeatureBox
          headingText="Boost Your Wellness"
          subText="Experience the true flavor of nature with every biteElevate your health with our premium organic products for a vibrant, healthier you"
          icon="basic-world"
        />
        <FeatureBox
          headingText="Taste Pure Goodness"
          subText="Enjoy the authentic flavors of nature with our fresh, organic products. Each bite offers unadulterated quality and pure taste"
          icon="basic-compass"
        />
        <FeatureBox
          headingText="Live Vibrantly"
          subText="Embrace the true vitality of wholesome, organic foods crafted to support a naturally energetic and vibrant, healthy life"
          icon="basic-heart"
        />
        <FeatureBox
          headingText="Nourish Naturally"
          subText="Handpicked, premium organic ingredients crafted to elevate your lifestyle and enhance overall well-being. Enjoy the benefits of natural nourishment daily"
          icon="basic-map"
        />
      </div>
    </section>
  );
};

export default FeatureScreen;
