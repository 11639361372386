import styles from "./about-section.module.scss";
import Button from "../../ui/Button";

const AboutScreen = () => {
  return (
    <section className={styles.section_about} id="about">
      <div
        className={[styles.u_center_text, styles.u_margin_bottom_big].join(" ")}
      >
        <h2 className={styles.headingSecondary}>
        Premium Dry Fruits, Honey, Seeds, & More
        </h2>
      </div>
      <div className={styles.row}>
        <div className={styles.col_1_of_2}>
          <h3
            className={[
              styles.headingTertiary,
              styles.u_margin_bottom_small,
            ].join(" ")}
          >
            Pure Organic Delights
          </h3>
          <p className={styles.paragraph}>
          Explore our curated selection of premium organic products—dry fruits, honey, dates, seeds, and more. Sourced for purity and quality, our offerings are perfect for enhancing your well-being and delighting your taste buds.
          </p>
          <h3
            className={[
              styles.headingTertiary,
              styles.u_margin_bottom_small,
            ].join(" ")}
          >
            Why You’ll Love It
          </h3>
          <p className={styles.paragraph}>
          Experience the natural goodness of our carefully selected products. Each item is packed with flavor and nutrients, providing you with the freshest, most wholesome ingredients nature has to offer.
          </p>
          <Button buttonClass="btn btn__green">Learn More &rarr; </Button>
        </div>
        <div className={styles.col_1_of_2}>
          <div className={styles.composition}>
            <img
              src="/images/nat-1.jpg"
              alt="About 1"
              className={[
                styles.composition_photo,
                styles.composition_photo__p1,
              ].join(" ")}
            />
            <img
              src="/images/nat-2-large.jpg"
              alt="About 2"
              className={[
                styles.composition_photo,
                styles.composition_photo__p2,
              ].join(" ")}
            />
            <img
              src="/images/nat-3-large.jpg"
              alt="About 3"
              className={[
                styles.composition_photo,
                styles.composition_photo__p3,
              ].join(" ")}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutScreen;
