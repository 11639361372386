// src/pages/Product.js
import React from 'react';
import Seo from './Seo';

const Product = () => {
    const products = [
        {
            name: "Honey Mixed Dry Fruit",
            description: "Indulge in the perfect combination of nature's sweetness with our Honey Mixed Dry Fruit blend...",
            price: 2000,
            image: "https://natureses.com/images/image-1724692853595.jpg",
            url: "https://natureses.com/product/honey-mixed-dry-fruit",
            rating: 5,
            numReviews: 4,
            brand: "Nature’s Essence",
            category: "Dry Fruit",
            sku: "HMDF-500",
            weight: "500 Grams",
            availability: "InStock",
            reviews: [
                {
                    name: "Nouman Dilshad",
                    rating: 5,
                    comment: "Best Product. Honey is so real."
                },
                {
                    name: "Saba Ali",
                    rating: 5,
                    comment: "Good"
                },
                {
                    name: "Talha Sajid",
                    rating: 5,
                    comment: "Received Thanks"
                },
                {
                    name: "Messam e Tammar",
                    rating: 5,
                    comment: "Honey is real"
                }
            ]
        },
        {
            name: "Jungli Honey",
            description: "Experience the natural sweetness of our Honey Pure Jungli, harvested from the wild forests...",
            price: 2500,
            image: "https://natureses.com/images/honey.jpg",
            url: "https://natureses.com/product/jungli-honey",
            rating: 0,
            numReviews: 0,
            brand: "Nature’s Essence",
            category: "Honey",
            sku: "JH-1KG",
            weight: "500 Grams",
            availability: "InStock",
            reviews: []
        },
        {
            name: "Multi Seeds Mix",
            description: "Discover the perfect blend of nutrition and taste with our Multi Seeds Mix...",
            price: 1200,
            image: "https://natureses.com/images/nat-3-large.jpg",
            url: "https://natureses.com/product/multi-seeds-mix",
            rating: 0,
            numReviews: 0,
            brand: "Nature’s Essence",
            category: "Seeds",
            sku: "MSM-500",
            weight: "250 Grams",
            availability: "InStock",
            reviews: []
        },
        {
            name: "Honey Multi Seeds",
            description: "Elevate your snacking experience with our Honey-Drizzled Multi Seeds...",
            price: 2000,
            image: "https://natureses.com/images/nat-7.jpg",
            url: "https://natureses.com/product/honey-multi-seeds",
            rating: 0,
            numReviews: 0,
            brand: "Nature’s Essence",
            category: "Seeds",
            sku: "HMS-500",
            weight: "250 Grams",
            availability: "InStock",
            reviews: []
        }
    ];

    return (
        <div>
            {products.map(product => (
                <div key={product.sku}>
                    <Seo product={product} />
                </div>
            ))}
        </div>
    );
};

export default Product;
