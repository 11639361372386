import React from "react";
import "./PrivacyPolicy.css"; // Add a CSS file for styling if needed
import Footer from "components/HomeScreen/FooterSection/FooterSection";
import Header from "../../components/layout/Header";

const PrivacyPolicy = () => {
  return (
    <>
    <Header />
    <div className="privacy-container">
      <h1 className="privacy-header">Privacy Policy</h1>
      <p className="privacy-paragraph">
        At Nature's Essence, we value your trust and are committed to ensuring the privacy and security of your personal information. This privacy policy outlines the types of personal data we collect, how we use that information, and the steps we take to protect it.
      </p>

      <h2 className="privacy-subheader">1. Information We Collect</h2>
      <p className="privacy-paragraph">
        When you interact with our website, we may collect certain personal information such as:
        <ul>
          <li>Your name, email address, phone number, and shipping address when placing an order.</li>
          <li>Payment details, which are processed securely through third-party payment processors.</li>
          <li>Information related to your preferences and browsing behavior through cookies and similar technologies.</li>
        </ul>
        This information is necessary to fulfill your orders, provide you with a better shopping experience, and offer tailored recommendations.
      </p>

      <h2 className="privacy-subheader">2. How We Use Your Information</h2>
      <p className="privacy-paragraph">
        We use the personal information you provide for various purposes, including:
        <ul>
          <li>Processing and fulfilling your orders, including sending order confirmations and delivery notifications.</li>
          <li>Providing customer support and responding to your inquiries.</li>
          <li>Sending promotional emails, newsletters, and special offers, only if you have opted in.</li>
          <li>Improving our website and services by analyzing your preferences and browsing behavior.</li>
        </ul>
      </p>

      <h2 className="privacy-subheader">3. Sharing of Information</h2>
      <p className="privacy-paragraph">
        We respect your privacy and do not sell, trade, or rent your personal information to third parties. However, we may share your information with:
        <ul>
          <li>Trusted service providers (such as payment processors, delivery services, and email service providers) who assist in operating our website and fulfilling your orders.</li>
          <li>Authorities or legal bodies, if required to comply with legal obligations.</li>
        </ul>
      </p>

      <h2 className="privacy-subheader">4. Cookies and Tracking Technologies</h2>
      <p className="privacy-paragraph">
        Our website uses cookies to enhance your experience. These small text files are stored on your device and help us remember your preferences, log in details, and provide personalized recommendations. You may choose to disable cookies through your browser settings, but this may affect your experience on our site.
      </p>

      <h2 className="privacy-subheader">5. Data Security</h2>
      <p className="privacy-paragraph">
        We implement a variety of security measures to maintain the safety of your personal information. This includes encryption, secure servers, and restricted access to personal data. While we strive to protect your information, no system is completely secure, and we cannot guarantee absolute security.
      </p>

      <h2 className="privacy-subheader">6. Your Rights</h2>
      <p className="privacy-paragraph">
        You have the right to:
        <ul>
          <li>Access the personal information we hold about you.</li>
          <li>Request the correction or deletion of your personal data.</li>
          <li>Opt-out of marketing communications at any time by clicking the unsubscribe link in emails or contacting us directly.</li>
        </ul>
        For any of these requests, you can contact us at <strong>support@natureses.com</strong>.
      </p>

      <h2 className="privacy-subheader">7. Changes to Our Privacy Policy</h2>
      <p className="privacy-paragraph">
        We may update this privacy policy from time to time. Any changes will be posted on this page with an updated revision date. We encourage you to review this policy periodically to stay informed about how we are protecting your data.
      </p>

      <h2 className="privacy-subheader">8. Contact Us</h2>
      <p className="privacy-paragraph">
        If you have any questions or concerns regarding our privacy policy, feel free to reach out to us at <strong>support@natureses.com</strong>. We are here to address any concerns you may have.
      </p>

      <p className="privacy-footer">Effective Date: September 8, 2024</p>
    </div>
    <Footer />
    </>  
  );
};

export default PrivacyPolicy;
