import Button from "../../ui/Button";
import styles from "./review-section.module.scss";

const ReviewSection = () => {
  return (
    <section className={styles.section_review} id="review">
      <div className={styles.bg_video}>
        <img
              src="images/video.jpg"
              alt="natures"
              className={styles.bg_video__content}
            />
      </div>
      <div
        className={[styles.u_center_text, styles.u_margin_bottom_big].join(" ")}
      >
        <h2 className={styles.headingSecondary}>
          We make people genuinely happy
        </h2>
      </div>
      <div className={styles.row}>
        <div className={styles.review}>
          <figure className={styles.review_shape}>
            <img
              src="images/nat-8.jpg"
              alt="Person one"
              className={styles.review_image}
            />
            <figcaption className={styles.review_caption}>
              Mary Smith
            </figcaption>
          </figure>
          <div className={styles.review_text}>
            <h3
              className={[
                styles.headingTertiary,
                styles.u_margin_bottom_small,
              ].join(" ")}
            >
              Nature's Essence Brings Joy and Quality to Every Bite
            </h3>
            <p>
            I absolutely love Nature's Essence! The Honey Pure Jungli is delicious, and the dry fruits are always so fresh. These products have become a staple in my daily routine. Highly recommend!
            </p>
          </div>
        </div>
        <div className={styles.review}>
          <figure className={styles.review_shape}>
            <img
              src="images/nat-9.jpg"
              alt="Person one"
              className={styles.review_image}
            />
            <figcaption className={styles.review_caption}>Joe Biden</figcaption>
          </figure>
          <div className={styles.review_text}>
            <h3
              className={[
                styles.headingTertiary,
                styles.u_margin_bottom_small,
              ].join(" ")}
            >
              Great Taste and Quality Products
            </h3>
            <p>
            I’m really impressed with Nature’s Essence! The honey is so pure and the dry fruits are always fresh. It feels good to know I’m eating healthy and tasty food.
            </p>
          </div>
        </div>
      </div>
      <div
        className={[styles.u_center_text, styles.u_margin_top_big].join(" ")}
      >
        <Button buttonClass="btn btn__green">Read More &rarr;</Button>
      </div>
    </section>
  );
};

export default ReviewSection;
