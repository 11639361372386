import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadProductList } from "../../app/slice/productListSlice";

import Header from "../../components/layout/Header";
import Footer from "../../components/HomeScreen/FooterSection/FooterSection";

import Card from "../../components/HomeScreen/Card";
import styles from "./product.module.scss";

import { Fragment } from "react";

import Loader from "../../components/ui/Loader";
import { useHistory } from "react-router";
import Paginate from "components/Paginate";
import Button from "components/ui/Button";
import Message from "components/Message/Message";
import { addToCart } from "../../app/slice/cartSlice";
import Product from "components/Product";
const ProductListScreen = ({ match }) => {
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;
  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);
  // State to manage category selection
  const [category, setCategory] = useState("All");

  const productList = useSelector((state) => state.products.productList);
  
  const { products, loading, error } = productList;
  const { list, page, pages } = products;
  const [showAlert, setShowAlert] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const history = useHistory();
  useEffect(() => {
    dispatch(loadProductList(keyword, pageNumber, category));
  }, [dispatch, keyword, pageNumber, category]);

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const productElements = list.map((product) => {
    const handleRoute = () => {
      const fullLink = `/product/${product.slug}`;
      history.push(fullLink);
    };

  const addToCartHandler = () => {
      dispatch(addToCart(product._id, qty));
      setSelectedId(product._id)
      setShowAlert(true);
      setTimeout(() => {
        setQty(1)
      }, 3000);
    };
    return (
      <Fragment key={product._id}>
        <div className={styles.col_1_of_3}>
        <Product></Product>
        <Card
          _id={product._id}
          heading={product.name}
          color="orange"
          showAlert = {showAlert}
          price={product.price}
          image={product.image}
          onClick={handleRoute}
          setQty={(q)=>setQty(q)}
          qty={qty}
          category={product.category}
          brand={product.brand}
          rating={product.rating}
          weight={product.weight}
          selectedId={selectedId}
          addToCartHandler={(_id, qty)=>{addToCartHandler(_id, qty)}}
        />
      </div>
      </Fragment>
    );
  });

  return (
    <>
      <Header />

      <div className={styles.container}>
        {match.params.keyword ? (
          <Button
            buttonClass="goBack"
            onClick={() => {
              history.goBack();
            }}
          >
            {" "}
            GO BACK
          </Button>
        ) : null}

        <div className={styles.header}>
          <h1>
            {match.params.keyword ? `Results for ${keyword}` : "PRODUCTS"}
          </h1>
        </div>

        <div className={styles.filters}>
          <label className={styles.filterLabel} htmlFor="category">
            Category:
          </label>
          <div className={styles.filterSelectWrapper}>
            <select
              id="category"
              className={styles.filterSelect}
              value={category}
              onChange={handleCategoryChange}
            >
              <option value="All">All</option>
              <option value="honey">Honey</option>
              <option value="dryfruits">Dry Fruits</option>
              <option value="seed">Seeds</option>
              <option value="organic">Organic</option>
              {/* Add more categories as needed */}
            </select>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <>
            {error && <Message info="error">{error}</Message>}
            <div className={styles.row}>{productElements}</div>
            <Paginate pages={pages} page={page} keyword={keyword ? keyword : ""} />
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ProductListScreen;
