import styles from "./footer-section.module.scss";

const FooterSection = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer_logoBox}>Nature’s Essence</div>
      <div className={styles.row}>
        <div className={styles.col_1_of_2}>
          <div className={styles.footer_navigation}>
            <ul className={styles.footer_list}>
              <li className={styles.footer_item}>
                <a href="#d" className={styles.footer_link}>
                  Company
                </a>
              </li>
              <li className={styles.footer_item}>
                <a href="#a" className={styles.footer_link}>
                  Contact Us
                </a>
              </li>

              <li className={styles.footer_item}>
                <a href="#a" className={styles.footer_link}>
                  Carrers
                </a>
              </li>
              <li className={styles.footer_item}>
                <a href="/privacy-policy" className={styles.footer_link}>
                  Privacy Term
                </a>
              </li>
              <li className={styles.footer_item}>
                <a href="#a" className={styles.footer_link}>
                  terms
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.col_1_of_2}>
          <p className={styles.footer_copyright}>
            Crafted with Care by{" "}
            <a href="#a" className={styles.footer_link}>
              Atiq & M
            </a>{" "}
            | Nature’s Essence – Your Source for Premium Organic Products and Healthy Dry Fruits.{" "}
            <a href="/" className={styles.footer_link}>
            © 2024 Nature’s Essence.
            </a>
             Committed to Quality, Sustainability, and Customer Happiness. All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
