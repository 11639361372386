import Rating from "components/Rating";
import styles from "./card.module.scss";
import Quantity from "../../components/Quantity";
import Alert from "../../components/Alert/Alert";

const categoryStyle = {
  fontWeight: 'bold',               // Make the text bold
  fontFamily: 'Arial, sans-serif',  // Clean, modern font
  fontSize: '1.4rem',               // Slightly larger font size
  color: '#ffffff',                 // White color for contrast
  backgroundColor: '#ff5722',       // Deep orange background for contrast
  padding: '0.3rem 0.8rem',         // Padding around the text
  borderRadius: '5px',              // Rounded corners for a softer look
  'margin-right': '1.5rem',
  //'margin-left': '2rem'
}

// Inline style object for price text
const boldClassyStyle = {
  fontWeight: 'bold',               // Make the text bold
  fontFamily: 'Georgia, serif',      // Classy serif font
  fontSize: '1.6rem',                // Slightly larger font size
  color: '#ffffff',                  // White text color for contrast
  background: 'linear-gradient(to right, #388e3c, #4caf50)', // Green gradient background
  padding: '0.1rem 0.7rem 0rem',            // More padding for space
  borderRadius: '8px',               // Rounded corners for a softer look
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // More pronounced box shadow for depth
  textAlign: 'center',               // Center the text
  display: 'inline-block',           // Make the span fit content size
};

// Inline style object for rating component
const ratingStyle = {
  fontSize: '1.5rem',               // Larger font size for rating
  color: '#ff5722',                 // Deep orange color for stars or rating text
  padding: '1rem'
};
const Card = ({
  _id,
  color,
  heading,
  price,
  image,
  onClick,
  category = "Organic Product",
  brand = "Nature’s Essence",
  weight,
  rating = 5,
  setQty,
  countInStock = 10,
  qty = 1,
  showAlert,
  addToCartHandler,
  selectedId
}) => {
  console.log(image);
  const imgStyles = {
    backgroundImage: `
      linear-gradient(
          to right bottom,#ffb900,
            #ff7730
        ),
        url(${image.replace(/\\/g, "/") ? image.replace(/\\/g, "/") : image}) 
    `,
  };

  return (
    <div className={styles.card}>
      <div className={[styles.card_side, styles.card_side__front].join(" ")}>
      {showAlert && (_id == selectedId) && (
        <Alert
          message={`${qty} Items Added !!`}
          type="success"
          duration={3000} // 3 seconds
          button1Text="Check Cart"
          button1Link="/cart"
          button2Text="Continue Shopping"
          button2Link="/products"          
          />
      )}  
        <div
          className={
            color === "orange"
              ? [styles.card_picture].join(" ")
              : color === "blue"
              ? [styles.card_picture, styles.card_picture__3].join(" ")
              : [styles.card_picture, styles.card_picture__2].join(" ")
          }
          style={color === "orange" ? imgStyles : null}
        >
          &nbsp;
        </div>
        <h4 className={styles.card_heading}>
          <span
            className={
              color === "orange"
                ? [styles.card_heading_span, styles.card_heading_span__1].join(
                    " "
                  )
                : color === "blue"
                ? [styles.card_heading_span, styles.card_heading_span__3].join(
                    " "
                  )
                : [styles.card_heading_span, styles.card_heading_span__2].join(
                    " "
                  )
            }
          >
            {heading}
          </span>
        </h4>
        <div className={styles.card_details}>
          <ul>
            { weight &&      
          <span style={categoryStyle}>{weight}</span> 
            }
          <span style={boldClassyStyle}>RS. {price}</span> 
          <span>&nbsp;&nbsp;</span>
          <span style={ratingStyle}><Rating value={rating} /></span>
         </ul>
        </div>
      </div>
      <div
        className={
          color === "orange"
            ? [
                styles.card_side,
                styles.card_side__back,
                styles.card_side__back__1,
              ].join(" ")
            : color === "blue"
            ? [
                styles.card_side,
                styles.card_side__back,
                styles.card_side__back__3,
              ].join(" ")
            : [
                styles.card_side,
                styles.card_side__back,
                styles.card_side__back__2,
              ].join(" ")
        }
      >
        <div className={styles.card_cta}>
        {showAlert && (_id == selectedId) && (
        <Alert
          message={`${qty} Items Added !!`}
          type="success"
          duration={3000} // 4 seconds
          button1Text="Check Cart"
          button1Link="/cart"
          button2Text="Continue Shopping"
          button2Link="/products"          
          />
      )} 
          <div className={styles.card_priceBox}>
            <p className={styles.card_price__only}>Only</p>
            <p className={styles.card_price__value}>Rs. {qty * price}</p>
          </div>
          <button
            className={[styles.btn, styles.btn__white].join(" ")}
            onClick={onClick}
          >
            View Details
          </button>
          <div style={{'margin-bottom': '-15%', 'margin-top': '3%' }}>
          <Quantity
                      setQuantity={(q)=>setQty(q)}
                      maxValue={countInStock}
          />
          </div>
          <button
            className={[styles.btn, styles.btn__white].join(" ")}
            style={{'margin-top': '2%','margin-left': '34%'}}
            onClick={()=>{addToCartHandler(_id, qty)}}
          >
            Add To Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
